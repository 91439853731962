/* PUT MAIN APPLICATION STYLING HERE! REMOVE THESE, THEY ARE NOT BEING USED! */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Center-Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

html,
body {
  margin: 0;
  height: 100%;
}

@media only screen and (min-device-width: 1212px) {
  #root {
    margin-right: 35%;
    margin-left: 35%;
  }
}
